<template>
    <v-container fluid class="outer" fill-height>
        <v-row class="purple lighten-1">
            <v-col class="text-center align-self-center">
                <v-text-field
                    outlined
                    dense
                    hide-details
                    :value="synopsisBlock.title"
                    placeholder="Mon chapitre"
                    @change="updateValue('title', $event)"
                    class="headerField"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row class="header purple lighten-2">
            <v-col md="2" class="margin text-center">Interêt pédagogique</v-col>
            <v-col md="6" class="margin text-center">Contenus formatif proposé</v-col>
            <v-col md="4" class="text-center">Types de visuels</v-col>
        </v-row>
        <v-row v-for="synopsisElement in filteredElements" :key="synopsisElement.id" class="mb-1 white">
            <v-col md="2" class="margin pa-0">
                <v-select
                    placeholder="Interêt pédagogique"
                    :value="synopsisElement.interest"
                    @change="updateValue('interest', $event, synopsisElement.id)"
                    :items="interestList"
                    height="72px"
                    class="ma-0 pa-0"
                    hide-details
                ></v-select>
            </v-col>
            <v-col md="6" class="margin pa-0 ma-0">
                <v-textarea
                    :value="synopsisElement.element"
                    @change="updateValue('element', $event, synopsisElement.id)"
                    hide-details
                    class="pa-0 my-0 ml-2"
                    rows="3"
                    auto-grow
                ></v-textarea>
            </v-col>
            <v-col md="4" class="pa-0 ma-0">
                <v-textarea
                    :value="synopsisElement.visuals"
                    @change="updateValue('visuals', $event, synopsisElement.id)"
                    placeholder="Décrivez ici les types de visuels qui seront utilisés"
                    hide-details
                    class="pa-0 my-0 ml-2"
                    rows="3"
                    auto-grow
                    append-outer-icon="fas fa-times"
                    @click:append-outer="deleteSynopsisElement(synopsisElement.id)"
                ></v-textarea>
            </v-col>
        </v-row>
        <v-row class="white">
            <v-col md="2" class="margin pa-0">
                <v-select
                    placeholder="Interêt pédagogique"
                    v-model="newInterest"
                    @change="createElement('interest')"
                    :items="interestList"
                    height="72px"
                    color="darkblue"
                    class="ma-0 pa-0"
                    hide-details
                ></v-select>
            </v-col>
            <v-col md="6" class="margin pa-0">
                <v-textarea
                    v-model="newElement"
                    @blur="createElement('element')"
                    placeholder="Contenu pédagogique"
                    hide-details
                    class="pa-0 my-0 ml-2"
                    rows="3"
                    auto-grow
                ></v-textarea>
            </v-col>
            <v-col md="4" class="pa-0 ma-0">
                <v-textarea
                    hide-details
                    class="pa-0 my-0 ml-2"
                    placeholder="Décrivez ici les types de visuels qui seront utilisés"
                    rows="3"
                    auto-grow
                ></v-textarea>
            </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="400">
            <v-card>
                <v-card-title>Suppression </v-card-title>
                <v-card-text>Êtes vous sûr de vouloir supprimer ce block ainsi que tous les éléments qui lui sont associés</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="
                            dialog = false;
                            askForDelete = null;
                        "
                    >
                        Annuler
                    </v-btn>

                    <v-btn
                        color="error darken-2"
                        text
                        @click="
                            dialog = false;
                            deleteSynopsisBlock();
                        "
                    >
                        Supprimer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
    name: "SynopsisBlock",

    props: {
        synopsisBlock: {
            type: Object,
            required: true,
        },
        showDelete: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        newInterest: 0,
        newObject: 0,
        newElement: "",

        dialog: false,
        askForDelete: null,


    }),

    computed: {
        ...mapState("synopsis", ["currentSynopsis", "synopsisElements"]),
        ...mapState("global", ["interestList", "objectList"]),

        filteredElements: function() {
            return this.synopsisElements.filter((obj) => obj.synopsisBlockId === this.synopsisBlock.id);
        },
    },

    methods: {
        ...mapActions("synopsis", [
            "modifySynopsisBlock",
            "removeSynopsisBlock",

            "addSynopsisElement",
            "modifySynopsisElement",
            "removeSynopsisElement",
        ]),

        // TODO: debounce ?
        updateValue: function(element, value, id) {
            if (id) {
                this.modifySynopsisElement({
                    id: id,
                    [element]: value,
                });
            } else {
                this.modifySynopsisBlock({
                    id: this.synopsisBlock.id,
                    [element]: value,
                });
            }
        },

        createElement: function(element) {
            let value;

            if (element === "interest") {
                value = this.newInterest;
                this.newInterest = null;
            } else if (element === "object") {
                value = this.newObject;
                this.newObject = null;
            } else if (element === "element") {
                value = this.newElement;
                this.newElement = "";
            }

            this.addSynopsisElement({
                synopsisId: this.currentSynopsis,
                synopsisBlockId: this.synopsisBlock.id,
                [element]: value,
            });
        },

        deleteSynopsisBlock: function() {
            this.removeSynopsisBlock(this.synopsisBlock.id);
            this.askForDelete = null;
        },

        deleteSynopsisElement: function(id) {
            this.removeSynopsisElement(id);
        },
    },
};
</script>

<style scoped>
.outer >>> .row {
    border: 1px solid black !important;
}

.margin {
    border-right: black solid 1px;
}

.v-text-field >>> .v-select__slot {
    margin-left: 15px;
}

.v-select >>> .v-input__slot:before,
.v-select >>> .v-input__slot:after {
    content: none;
}

.headerField >>> input {
    text-align: center;
}

.v-text-field >>> .v-input__slot::before,
.v-text-field >>> .v-input__slot::after {
    content: none;
}
</style>
