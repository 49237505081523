<template>
    <v-container fluid>
        <page-banner links="Ma ressource"></page-banner>
        <formation-header></formation-header>

        <SynopsisBlock
            v-for="block in synopsisBlocks"
            :key="block.id"
            :synopsisBlock="block"
            :showDelete="synopsisBlocks.length > 1"
            class="mt-5"
        ></SynopsisBlock>
        <v-row class="mt-5">
            <v-btn icon @click="newSynopsisBlock"><v-icon color="black">fas fa-plus</v-icon></v-btn>
        </v-row>
    </v-container>
</template>

<script>
import SynopsisBlock from "@/components/designer/SynopsisBlock";
import { mapActions, mapState } from "vuex";
import PageBanner from "@/components/PageBanner";
import FormationHeader from "@/components/designer/FormationHeader";

export default {
    name: "SynopsisDesigner",
    components: {
        PageBanner,
        SynopsisBlock,
        FormationHeader,
    },

    computed: {
        ...mapState("synopsis", ["synopsisBlocks"]),

        synopsisId() {
            const synopsisId = this.$route.params.id;

            if (synopsisId !== this.currentSynopsis) {
                this.setCurrentSynopsis(synopsisId);
            }

            return this.$route.params.id;
        },

        synopsis() {
            return this.$store.getters["synopsis/getSynopsisById"](this.synopsisId);
        },
    },

    methods: {
        ...mapActions("synopsis", ["setCurrentSynopsis", "addSynopsisBlock"]),

        newSynopsisBlock: function() {
            this.addSynopsisBlock({});
        },
    },
};
</script>

<style scoped></style>
